//import { bool, cleanEnv, url, str } from 'envalid';
import axios from 'axios';
import meta from '@/meta.json';

/*
const envVariables = cleanEnv(import.meta.env, {
  VITE_ADMIN_WEBSOCKET: url({ default: 'wss://admin.teotrainingtest.be' }),
  VITE_ENABLE_DEBUG: bool({ default: false }),
  VITE_ENABLE_MSW: bool({ default: false }),
  VITE_LANGS: str({ default: 'nl,en,fr,es' }),
  VITE_S3_ROOT: url({
    default: 'https://s3.eu-west-1.amazonaws.com/teo-s3-dev/',
  }),
  VITE_URL_ADMIN: url({ default: 'https://admin.teotrainingtest.be' }),
  VITE_URL_BACKEND: url({ default: 'https://teotrainingtest.be/api' }),
  VITE_URL_DOCS: url({ default: 'https://teotrainingtest.be/teodoc' }),
  VITE_URL_LOGIN: url({ default: 'https://auth.teotrainingtest.be' }),
  VITE_IFRAMELY_API: url({ default: 'da2e65c0ad1f3ecf745813db99ab600f' })
});*/

export const config: any = {
  langs: ['nl', 'en', 'fr', 'es'],
};

//if (window.location.hostname === 'new.teotrainingtest.be') {
config.backend = 'https://teotrainingtest.be/api';
config.debug = false;
config.enableMsw = false;
config.s3root = 'https://s3.eu-west-1.amazonaws.com/teo-s3-acc/';
config.teoadmin = 'https://admin.teotrainingtest.be';
config.teoadminWebsocket = 'wss://admin.teotrainingtest.be';
config.teodoc = 'https://teotrainingtest.be/teodoc';
config.teologin = 'https://auth.teotrainingtest.be';
config.teoreport = 'https://teotrainingtest.be/teo_report';
config.iframely = 'da2e65c0ad1f3ecf745813db99ab600f';
config.version = '';
//}

if (window.location.hostname === 'teo.training') {
  config.backend = 'https://teo.training/api';
  config.debug = false;
  config.enableMsw = false;
  config.s3root = 'https://s3.eu-west-1.amazonaws.com/teo-s3-prod/';
  config.teoadmin = 'https://admin.teo.training';
  config.teoadminWebsocket = 'wss://admin.teo.training';
  config.teodoc = 'https://teo.training/teodoc';
  config.teologin = 'https://auth.teo.training';
  config.teoreport = 'https://teo.training/teo_report';
  config.iframely = 'da2e65c0ad1f3ecf745813db99ab600f';
  config.version = meta.version;
}

if (window.location.hostname === 'localhost') {
  //config.backend = 'http://localhost:3000/api';
  //config.teologin = 'http://localhost:3004';
  config.teoreport = 'http://localhost:3005/teo_report';
  //config.s3root = 'https://s3.eu-west-1.amazonaws.com/teo-s3-acc/';
}

console.warn('loading config', config);

config.policy = new Promise((resolve, reject) => {
  axios
    .post(`${config.backend}/document/policy`, { path: 'froala/' })
    .then((result: any) => resolve(result.data))
    .catch(reject);
});
